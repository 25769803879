export const genres = [
  "C-Pop",
  "J-Pop",
  "K-Pop",
  "Avant-garde",
  "Blues",
  "Caribbean",
  "Drum and Bass",
  "Classical",
  "Electronic Rock",
  "Electronica",
  "Comedy",
  "Country",
  "Easy Listening",
  "Ambient",
  "Folk",
  "Hip-Hop and Rap",
  "House",
  "Hardcore Electronic",
  "Industrial",
  "Techno",
  "Trance",
  "Holiday",
  "Christian and Gospel",
  "Jazz",
  "Latin",
  "Metal",
  "Electronic",
  "Pop",
  "Punk",
  "R&B and Soul",
  "Rock",
  "Reggae",
  "Soundtracks and Scores",
  "Vocal",
  "New-Age",
  "World"
];