const ArtistDisplayActionTypes = {
    SET_CURRENT_ARTIST_ID: "SET_CURRENT_ARTIST_ID",
    SET_CURRENT_ARTIST_DATA: "SET_CURRENT_ARTIST_DATA",
    SET_CURRENT_ARTIST_PROJECT_IDS: "SET_CURRENT_ARTIST_PROJECT_IDS",
    SET_CURRENT_ARTIST_PROJECT_DATA: "SET_CURRENT_ARTIST_PROJECT_DATA",
    SET_CURRENT_ARTIST_TRACK_DATA: "SET_CURRENT_ARTIST_TRACK_DATA",
    SET_CURRENT_ARTIST_TRACK_IDS: "SET_CURRENT_ARTIST_TRACK_IDS",
    SET_ARTIST_CREATED_PLAYLIST_INDEX: "SET_ARTIST_CREATED_PLAYLIST_INDEX",
    SET_ARTIST_CREATED_PLAYLIST_DATA: "SET_ARTIST_CREATED_PLAYLIST_DATA",
    SET_ARTIST_CREATED_PLAYLIST_IDS: "SET_ARTIST_CREATED_PLAYLIST_IDS",   
  };
  
  export default ArtistDisplayActionTypes;
  