const UserDataActionTypes = {
    SET_USER_DATA: "SET_USER_DATA",
    SET_USER_ID: "SET_USER_ID",
    SET_CURRENT_ARTIST_INDEX: "SET_CURRENT_ARTIST_INDEX",
    SET_USER_ARTIST_DATA: "SET_USER_ARTISTS_DATA",
    SET_USER_ARTIST_IDS: "SET_USER_ARTIST_IDS",
    SET_ARTIST_PROJECT_DATA: "SET_ARTIST_PROJECT_DATA",
    SET_ARTIST_PROJECT_IDS: "SET_ARTIST_PROJECT_IDS",
    SET_PROJECT_SONG_DATA: "SET_PROJECT_SONG_DATA",
    SET_PROJECT_SONG_IDS: "SET_PROJECT_SONG_IDS",
    SET_CURRENT_PROJECT_INDEX: "SET_CURRENT_PROJECT_INDEX",
  };
  
  export default UserDataActionTypes;
  