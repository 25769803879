const ProjectDisplayActionTypes = {
    SET_CURRENT_PROJECT_ARTIST_ID: "SET_CURRENT_ARTIST_ID",
    SET_CURRENT_PROJECT_ARTIST_DATA: "SET_CURRENT_ARTIST_DATA",
    SET_CURRENT_PROJECT_ID: "SET_CURRENT_PROJECT_ID",
    SET_CURRENT_PROJECT_DATA: "SET_CURRENT_PROJECT_DATA",
    SET_CURRENT_PROJECT_SONG_IDS: "SET_CURRENT_PROJECT_SONG_IDS",
    SET_CURRENT_PROJECT_SONG_DATA: "SET_CURRENT_PROJECT_SONG_DATA",
    SET_CREATED_PLAYLIST_INDEX: "SET_CREATED_PLAYLIST_INDEX",
    SET_CREATED_PLAYLIST_DATA: "SET_CREATED_PLAYLIST_DATA",
    SET_CREATED_PLAYLIST_IDS: "SET_CREATED_PLAYLIST_IDS",   
  };
  
  export default ProjectDisplayActionTypes;
  