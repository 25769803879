const ArtistManagementTypes = {
    SET_ARTIST_DISPLAY: "SET_ARTIST_DISPLAY",
    SET_ARTIST_FORM: "SET_ARTIST_FORM",
    SET_PROJECT_DISPLAY: "SET_PROJECT_DISPLAY",
    SET_PROJECT_FORM: "SET_PROJECT_FORM",
    SET_UPLOAD_COUNT: "SET_UPLOAD_COUNT",
    SET_UPLOAD_PROGRESS: "SET_UPLOAD_PROGRESS",
    SET_UPLOAD_INDEX: "SET_UPLOAD_INDEX",
  };
  
  export default ArtistManagementTypes;