const PlaylistDisplayActionTypes = {
    SET_RELATED_PLAYLIST_IDS: "SET_RELATED_PLAYLIST_IDS",
    SET_RELATED_PLAYLIST_DATA: "SET_RELATED_PLAYLIST_DATA",
    SET_CURRENT_PLAYLIST_ID: "SET_CURRENT_PLAYLIST_ID",
    SET_CURRENT_PLAYLIST_DATA: "SET_CURRENT_PLAYLIST_DATA",
    SET_PLAYLIST_SONG_IDS: "SET_PLAYLIST_SONG_IDS",
    SET_PLAYLIST_SONG_DATA: "SET_PLAYLIST_SONG_DATA",
    SET_CREATED_PLAYLIST_INDEX: "SET_CREATED_PLAYLIST_INDEX",
    SET_CREATED_PLAYLIST_DATA: "SET_CREATED_PLAYLIST_DATA",
    SET_CREATED_PLAYLIST_IDS: "SET_CREATED_PLAYLIST_IDS",   
  };
  
  export default PlaylistDisplayActionTypes;
  