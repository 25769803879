const HomeActionTypes = {
    SET_HOME_STATE: "SET_HOME_STATE",
    SET_LIBRARY_STATE: "SET_LIBRARY_STATE",
    SET_FOLLOWED_ARTIST_IDS: "SET_FOLLOWED_ARTIST_IDS",
    SET_FOLLOWED_ARTIST_DATA: "SET_FOLLOWED_ARTIST_DATA",
    SET_FOLLOWED_ARTIST_PROJECT_IDS: "SET_FOLLOWED_ARTIST_PROJECT_IDS",
    SET_FOLLOWED_ARTIST_PROJECT_DATA: "SET_FOLLOWED_ARTIST_PROJECT_DATA",
    SET_LIKED_SONG_IDS: "SET_LIKED_SONG_IDS",
    SET_LIKED_SONG_DATA: "SET_LIKED_SONG_DATA",
    SET_BOOKMARKED_PROJECT_IDS: "SET_BOOKMARKED_PROJECT_IDS",
    SET_BOOKMARKED_PROJECT_DATA: "SET_BOOKMARKED_PROJECT_DATA",
    SET_SAVED_PLAYLIST_IDS: "SET_SAVED_PLAYLIST_IDS",
    SET_SAVED_PLAYLIST_DATA: "SET_SAVED_PLAYLIST_DATA",
    SET_CREATED_PLAYLIST_IDS: "SET_CREATED_PLAYLIST_IDS",
    SET_CREATED_PLAYLIST_DATA: "SET_CREATED_PLAYLIST_DATA",
    SET_CURRENT_PLAYLIST_INDEX: "SET_CURRENT_PLAYLIST_INDEX",
    SET_PLAYLIST_STATE: "SET_PLAYLIST_STATE",
    SET_CURRENT_PLAYLIST_SONG_DATA: "SET_CURRENT_PLAYLIST_SONG_DATA",
    SET_CURRENT_PLAYLIST_SONG_IDS: "SET_CURRENT_PLAYLIST_SONG_IDS"
  };
  
  export default HomeActionTypes;
  